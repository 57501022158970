<template>
  <v-container class="mt-0">
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="4" sm="8" xs="12" class="d-flex justify-space-around">
        <v-card class="card-shadow border-radius-xl" width="363" min-width="363" max-width="363"
          style="border: 1px solid #4caf5033">
          <v-form ref="frm">
            <div class="card-padding text-center pb-0" align="center" justify="center" flex>
              <v-row justify-space-between>
                <v-col class="d-flex justify-space-around pb-0">
                  <v-img contain align="center" justify="center" class="navbar-brand-img ms-3" width="10" max-width="120"
                    style="border-radius: 50%;" src="../../../../assets/img/curved-images/EASTEA_LOGO_LOGIN.jpeg"></v-img>
                </v-col>
              </v-row>
            </div>
            <div class="card-padding">
              <v-text-field solo :rules="usernameRules" placeholder="Username" prepend-inner-icon="fa-user"
                v-model="authData.user_name">
              </v-text-field>
              <v-text-field solo prepend-inner-icon=" fa-lock" placeholder="Password" v-model="authData.password"
                :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'" :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'" name="input-10-1" @click:append="show1 = !show1"></v-text-field>
              <v-btn elevation="0" :ripple="false" height="43" dark class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  py-2
                  px-6
                  me-2
                  mb-2
                  w-100
                  green
                " @click="login()" color="#008946" small>Sign In</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "sign-up-basic",
  data() {
    return {
      is_error: false,
      authData: {
        user_name: "",
        password: "",
      },
      error: "",
      usernameRules: [(v) => !!v || ""],
      show1: false,
      show2: true,
      password: "Password",
      rules: {
        required: (value) => !!value || "",
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    login() {
      if (this.validate()) {
        axios
          .post("auth-module/login-user", this.authData)
          .then(async (res) => {
            localStorage.removeItem("token_pnb");
            localStorage.setItem("token_pnb", res.data.token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.token;
            this.getRoutingPermissions();
            this.$router.push({ name: "State" });
          })
          .catch((err) => {
            console.log("err", err);
            this.error = err.response.data.message;
            this.is_error = true;
            this.showErrorAlert(err.response.data.message);
          });
      }
    },
    getRoutingPermissions() {
      this.$store.dispatch("addRoutingPermissions");
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
  },
};
</script>
<style>
.v-image__image--contain {
  background-position: left center !important;
}

.copyright {
  margin-top: 90px;
  margin-bottom: 0px !important;
  font-size: 13px !important;
}

.copyright a {
  font-size: 13px !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  box-shadow: none !important;
  border: 1px solid #e7e7e7;
}

.fa-user:before {
  content: "\f007";
  font-size: 12px;
  color: #c8c8c8;
}

.fa-lock:before {
  content: "\f023";
  font-size: 12px;
  color: #c8c8c8;
}

.fa-eye-slash:before {
  content: "\f070";
  font-size: 12px;
  color: #c8c8c8;
}

.fa-eye:before {
  content: "\f06e";
  font-size: 12px;
  color: #c8c8c8;
}

.v-text-field__details {
  display: none !important;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details::-webkit-input-placeholder {
  font-size: 14px;
}

.v-application .text-h5 {
  font-weight: 600 !important;
}
</style>
